/* code to setup some globals data needed by all ads */
//use one of the variables inside as the check to see if they all need to be generated
if (typeof TNCMS_LOADED_VARS == "undefined")
{
  if (!window.TNCMS)
  {
    window.TNCMS = {};
  }

  //all bAdManCurve stuff can be removed in the next release...
  //this is just here for backwards compatability while the migrate script is running
  bAdManCurve = typeof aTNCMS_Day_Impressions != "undefined";
  TNCMS_LOADED_VARS = true;
  TNCMS_Location = false;

  if (window.XMLHttpRequest)
  {
    oReq = new XMLHttpRequest();
  }
  else if (window.ActiveXObject)
  {
    oReq = new ActiveXObject("MSXML2.XMLHTTP.3.0");
  }

  if (oReq)
  {
    oReq.onreadystatechange = function()
    {
      if (oReq.readyState == 4 && oReq.status == 200)
      {
        var oResponse = eval("(" + oReq.responseText + ")");

        //if the lat and/or long isn't numeric then there are no proper
        //coordinates set for this IP so we can discard the info...
        if (oResponse['lat'] !== '' && oResponse['long'] !== '')
        {
          TNCMS_Location = {'lat': oResponse['lat'], 'long': oResponse['long']};
        }
      }
    };

    try
    {
      oReq.open("GET", "/_services/v1/client_ip_info/");
      oReq.send();
    }
    catch (e) {}
  }

  //add some debug code use adman_debug_time=10/17/2010_16-30 (military time) in querystring for page
  //to override the date object used for dayparting full date time required.
  aTime = location.search.match(/(\?|\&)adman_debug_time=(\d\d)\/(\d\d)\/(\d\d\d\d)_(\d\d)-(\d\d)/);
  oCurrent = aTime ? new Date(aTime[4], aTime[2]-1, aTime[3], aTime[5], aTime[6]) : new Date();
  delete aTime;

  //currently there is only one debug mode (1) but there may be more added latter...
  //debug mode 1 will display a position label
  //debug mode 4 will output info to console.log
  TNCMS_AdMan_Debug = location.search.match(/(\?|\&)adman_debug_mode=(\d+)/) ? RegExp.$2 : 0;

  //bounds are in 15 min increments
  //so current boundry is current hours * 4 + 1 / full quarter of an hour
  sBound = oCurrent.getHours() * 4 + Math.floor(oCurrent.getMinutes() / 15);
  TNCMS_Ad_DayPart = {day: oCurrent.getDay(), startBound: sBound, endBound: sBound + 1};
  delete sBound;
  delete oCurrent;

  TNCMS_Ad_timestamp = new Date().getTime();
  TNCMS_DotConnect_Tile = 1;
  TNCMS_Meta_Keywords = false;

  // "Global" Array to prevent showing of duplicates. 
  if (typeof TNCMS_Used_Ad == "undefined")
  {
    TNCMS_Used_Ad = {};
  }
  
  sURL = document.location.href;
  docPath = sURL.replace(/\?.*/, '').replace(/#\w+/, '');
  sPath = '';

  if (docPath.match(/^((http|ftp|https):\/)?\/?([^:\/\s]+)((\/[\w\-\.]+)*\/)([\w\-\.]+\.[^#?\s]+)([#\?].+)?$/))
  {
    sPath = RegExp.$4;
  }
  else if (docPath.match(/^((http|ftp|https):\/)?\/?([^:\/\s]+)((\/[\w\-\.]+)*\/)?$/))
  {
    sPath = RegExp.$4;
  }

  // handle frontpage special case. 
  TNCMS_Section = sPath == "/" ? 'frontpage' : sPath.replace(/^\//g,'').replace(/\/$/g,'');
  
  //clean up
  delete sURL;
  delete docPath;
  delete sPath;
}

if (typeof(tncms_ad_image) != 'function')
{
  tncms_ad_image = function(ad)
  {
    alt = ad.title ? ad.title : '*';
    sHeight = ad.height ? ' height="' + ad.height + '"' : '';
    sWidth = ad.width ? ' width="' + ad.width + '"' : '';
    sOnClick = '';

    if (ad.clickuri)
    {
      sOnClick = ' onclick="window.open(\'' + ad.clickuri + '\',\'_blank\');" style="cursor: pointer;"';
    }

    document.write('<img src="' + ad.asseturl + '" alt="' + alt + '" border="0"' + sHeight + sWidth + sOnClick + '/>');
  };
}

if (typeof(tncms_ad_html5) != 'function')
{
    tncms_ad_html5 = function(ad)
    {
        var sSrc= 'https://' + ad.cdn + 
            '/content/tncms/ads/__html5/' + 
            ad.uuid + '/' + 
            ad.html5_id + '/' + 
            ad.htmlfile;

        if(ad.clickuri){sSrc += '?clickTag=' + encodeURIComponent(ad.clickuri);}

        var sHTML = '<iframe id="html5-ad-frame-"' + ad.adid + 
            '" width="' + ad.width + '" height="' + ad.height + 
            '" src="' + sSrc + '" scrolling="no" hspace="0" vspace="0" ' +
            'sandbox="allow-forms allow-popups allow-same-origin allow-scripts" ' +
            'frameborder="0" marginwidth="0" marginheight="0"></iframe>';

        document.write(sHTML);
    };
}

if (typeof(tncms_ad_html) != 'function')
{
  tncms_ad_html = function(ad)
  {
    if (ad.html)
    {
      var html = eval(ad.html);
      document.write(html.replace(/__TRACKER_URL__/g, ad.clickuri));
    }
    else
    {
      var strippedUrl = ad.asseturl.replace(/\?_dc=[0-9]+/, '').replace(/\?dc=[0-9]+/, '');
      strippedUrl.match(/.+\/(.+?)\//);
      var sVarName = RegExp.$1;
      sVarName = sVarName.replace(/-/g, '');

      var sHTML = '<scr' + "ipt type=\"text/javascript\">\n";
      sHTML += "var pos_" + sVarName + " = '" + ad.position + "';\n";
      sHTML += "var sec_" + sVarName + " = '" + ad.section + "';\n";
      sHTML += "</scr" + "ipt>\n";
      sHTML += '<scr' + 'ipt type="text/javascript" src="' + ad.asseturl + '"></scr' + "ipt>\n";
      document.write(sHTML);
    }
  };
}

if ( typeof(tncms_ad_video) != 'function' ) {	
	tncms_ad_video = function ( ad ) {	
		if ( ad.embed == 1 ) {
			document.write('<!-- Embedded videos are not supported. -->');
			return;
		}
	  	  
		domain = location.protocol == 'https:' && ad.securedomain 
			? ad.securedomain 
			: ad.domain;
		
		if ( ad.cdn ) {
			assetdomain = 'https://' + ad.cdn;
		} else {
			assetdomain = location.protocol + '//' + domain;
		}
		
		ad.asseturl = assetdomain + ad.uri;
				
		var sHTML = '<div id="blox-ad-' + ad.adid + '">' 
			+ tncms_standalone_video(ad) + '</div>';
	    
	    document.write(sHTML);  
	}
}

if ( typeof(tncms_standalone_video) != 'function ') {
	tncms_standalone_video = function ( oVideoAd ) {		
		if ( ! oVideoAd || ! oVideoAd.asseturl.match(/\.mp4$/i) ) {
			return;
		}

		// Create CSS
		
		var oCSS = document.createElement('style');
		oCSS.appendChild(document.createTextNode(
			'.tncms-admanager-video-cnt {'
			+ ' width: 100%;'
			+ ' height: 100%;'
			+ ' text-align: center;'
			+ '}' + "\n"
			+ '.tncms-admanager-video-inner-cnt {'
			+ ' display:inline-block;'
			+ ' position:relative;'
			+ '}' + "\n"
			+ '.tncms-admanager-video-click-cnt {'
			+ ' position: absolute;'
			+ ' right: 10px;'
			+ ' top: 10px;'
			+ ' z-index: 1;'
			+ ' padding: 5px 5px;'
			+ ' font-size: 16px;'
			+ ' font-family: Helvetica;'
			+ ' color: #FFF;'
			+ ' background-color: rgba(50, 50, 50, 0.3);'
			+ '}' + "\n"    
			+ 'video {' 
			+ ' width: 100%;'
			+ ' height: 100%;'
			+ '}' + "\n"
			+ '.tncms-admanager-video-click-cnt a {'
			+ ' color: #DDD;'
			+ '}' + "\n"
		));
		
		// Create JS to pause video on link click
		
		var oJS = document.createElement('script');
		oJS.setAttribute('type', 'text/javascript');
		oJS.innerHTML = ' var tncmsAdmanagerVideoCtrl = function (sAdID, sAction) {'
			+ '  var oPlayer = document.getElementById(\'tncms-admanager-video-player-\' + sAdID);'
			+ '  if ( oPlayer ) {'
			+ '    switch(sAction) { '
			+ '       case \'pause\':'
			+ '          oPlayer.pause();'
			+ '          break;'
			+ '       case \'play\':'
			+ '         oPlayer.play();'
			+ '         break;'
			+ '    }'
			+ '  }'
			+ '} ';
		
		// Create base containers
		
		var oCntDiv = document.createElement('div');
		oCntDiv.setAttribute('class', 'tncms-admanager-video-cnt');
		oCntDiv.setAttribute('id', 'tncms-admanager-video-' + oVideoAd.adid);
		
		var oInnerDiv = document.createElement('div');
		oInnerDiv.setAttribute('class', 'tncms-admanager-video-inner-cnt');
		
		if ( oVideoAd.clickuri && oVideoAd.clickuri.length > 0 ) {
			
			// Create clickthru div and anchor
			
			var oClickDiv = document.createElement('div');
			oClickDiv.setAttribute('class', 'tncms-admanager-video-click-cnt');
			
			var oClickAnchor = document.createElement('a');
			oClickAnchor.setAttribute('class', 'tncms-admanager-video-click-url');    	
			oClickAnchor.setAttribute('href', oVideoAd.clickuri);
			oClickAnchor.setAttribute('target', oVideoAd.target || '_blank');
			oClickAnchor.setAttribute('onclick', 'tncmsAdmanagerVideoCtrl(\'' + oVideoAd.adid + '\', \'pause\')');
			oClickAnchor.innerHTML = 'Click here for details';			
		}
		
		// Create the base video tag
	
		var oVideoTag = document.createElement('video');
		oVideoTag.setAttribute('controls', '');
		oVideoTag.setAttribute('loop', '');
		oVideoTag.setAttribute('class', 'tncms-admanager-video');
		oVideoTag.setAttribute('id', 'tncms-admanager-video-player-' + oVideoAd.adid);
		oVideoTag.setAttribute('width', oVideoAd.width);
		oVideoTag.setAttribute('height', oVideoAd.height);
    	if ( oVideoAd.poster ) {
    		oVideoTag.setAttribute('poster', oVideoAd.poster);
    	}		
		
		if ( oVideoAd.autoplay ) {
			oVideoTag.setAttribute('autoplay', ''); 
			oVideoTag.setAttribute('muted', '');
		} else {
			oVideoTag.setAttribute('onloadstart', "this.volume=0.5");
		}
		
		// Create the source tag
		
		var oSourceTag = document.createElement('source');
		oSourceTag.setAttribute('src', oVideoAd.asseturl);
		oSourceTag.setAttribute('type', 'video/mp4');		
		
		// Append elements
		
		oVideoTag.appendChild(oSourceTag);
		oVideoTag.appendChild(document.createTextNode(
			'Video not supported by browser.'
		));
		
		if ( oClickDiv && oClickAnchor ) {
			oClickDiv.appendChild(oClickAnchor);		
			oInnerDiv.appendChild(oClickDiv);			
		}

		oInnerDiv.appendChild(oVideoTag);
		    	    
		oCntDiv.appendChild(oInnerDiv);
		
		// Return rendered HTML through a dummy container 
		
		var oDiv = document.createElement('div');		
		oDiv.appendChild(oCSS);
		oDiv.appendChild(oJS);
		oDiv.appendChild(oCntDiv);
		
		return oDiv.innerHTML;
	}
}

if (typeof(tncms_ad_dotconnect) != 'function')
{
    tncms_ad_dotconnect = function(ad)
    {
        var sSiteName = ad.domain.replace("www.","");
        var sPath = window.location.pathname;
        var aSections = sPath.split("/");
        var sSecondLevel = "frontpage";
        if(aSections.length > 1 && aSections[1].indexOf(".") == -1)
        {
            sSecondLevel = aSections[1];
        }

        //Fold targetting settings
        var sFoldPosition = ad.fold ? ad.fold : "";

        //Create the google tag Js file in the header of the page. 
        if (!window.googletag)
        {
            window.googletag = {};
            if (document.getElementById('dotconnect-gpt-load') === null)
            {
                var gads = document.createElement('script');
                gads.id = 'dotconnect-gpt-load';
                gads.async = true;
                gads.type = 'text/javascript';
                var useSSL = 'https:' == document.location.protocol;
                gads.src = (useSSL ? 'https:' : 'http:') + 
                    '//www.googletagservices.com/tag/js/gpt.js';
                var node = document.getElementsByTagName('head')[0];
                node.appendChild(gads,node);
            }
        }

        //Use the google tag object
        var googletag = window.googletag || {};
        googletag.cmd = googletag.cmd || [];
        var sDotConnectDiv = "blox-dotconnect-ad-" + ad.adid;
        document.write(
            "<div id='" + sDotConnectDiv + "' width='" + ad.width + 
            "' height='" + ad.height + "'></div>"
        );

        /*
         * Send the google tag request to google with slot level targeting and
         * page level targeting.
         */
        googletag.cmd.push(
            function()
            {
                googletag.defineSlot(
                    '/132916964/' + sSiteName,
                    [
                        parseInt(ad.width),
                        parseInt(ad.height)
                    ],
                    sDotConnectDiv
                ).addService(
                    googletag.pubads()
                ).setTargeting(
                    'pos',
                    sFoldPosition
                );
                googletag.pubads().setTargeting('sec',sSecondLevel);
                googletag.enableServices();
                googletag.display(sDotConnectDiv);
            }
        );
        //Increment the counter to keep ads under 16
        TNCMS_DotConnect_Tile++;
    };
}

/**
* TNCMS_Ad
* Ad Object for rendering and handling ads supplied from ad-manager
* Configuration:
*   ads: array of valid ad objects.
*   domain: the domain of the site hosting the ad.
*   position: the name of the position being referenced. 
* 
* Supports multiple types of ads including image, text and DotConnect ads.
*/ 
TNCMS_Ad =
{
  init: function(oCfg)
  {
    if (!oCfg.ads)
    {
      this.oAds = [];
      return this; 
    }

    //if this is being run from an old static position on a site without the tracking code
    if (!window.TNCMS.Tracking)
    {
      //then this will load it
      document.write('<scr' + 'ipt type="text/javascript" src="http://' + oCfg.domain + '/shared-content/art/tncms/tracking.js"></scr' + 'ipt>');
    }

    //the first time this runs, generate the keywords
    if (TNCMS_Meta_Keywords === false)
    {
      var aTag = document.getElementsByTagName('meta');
      var oKeyword = {};
      var aKeyword = [];

      for (var i = 0; i < aTag.length; i++)
      {
        if (aTag[i].name.toLowerCase() == 'keywords')
        {
          aTemp = aTag[i].content.split(',');

          for (var j = 0; j < aTemp.length; j++)
          {
            sTemp = aTemp[j].replace(/^\s+|\s+$/g,"");

            //push the temp name onto an object to keep the names unique
            //add a space on either end to narrow the search to the whole word and not just a part of it
            //only add the word if it's not empty
            if (sTemp)
            {
              oKeyword[' ' + sTemp + ' '] = 1;
            }
          }
        }
      }

      //push each now unique keyword on to the keyword list
      for (var s in oKeyword)
      {
        aKeyword.push(s);
      }

      //lowercase the keywords so the search can be case insensitive
      TNCMS_Meta_Keywords = aKeyword.join(',').toLowerCase();
    }

    this.oAds = oCfg.ads;
    this.sDomain = oCfg.domain;
    this.sSecureDomain = oCfg.securedomain ? oCfg.securedomain : false;
    this.sPosition = oCfg.position;  
    this.oPosSettings = oCfg.position_settings ? oCfg.position_settings : {};
    this.bRelative = false;
    this.iDebug = TNCMS_AdMan_Debug;
    this.bCanLog = false;
    this.nWidth = false;
    this.sFold = null;
    
    /**
     * Used to know if we are in secure mode so the proper
     * domain can be used for rendering.
     */
    this.isSecure = location.protocol == 'https:' ? true : false;

    if (this.iDebug > 0)
    {
      if (typeof console == "undefined")
      {
        alert('Please turn on the debugging console!');
      }
      else
      {
        this.info('Debug level set to: ' + this.iDebug);
        this.bCanLog = true;
      }
    }
    
    //get the current date and UTC timestamp based on the users timezone offset.
    var oDate = new Date();

    if (bAdManCurve)
    {
      iHour = oDate.getHours();
      this.nTrafficEstimate = typeof aTNCMS_Traffic_Estimate == "undefined" ? 0 : aTNCMS_Traffic_Estimate[iHour];
      this.nTrafficRatio = typeof aTNCMS_Traffic_Ratio == "undefined" ? 0 : aTNCMS_Traffic_Ratio[iHour];
    }
    else
    {
      this.nTodayTimestamp = Math.ceil(oDate.getTime() * .001);
    }

    return this;
  },

  info: function(message)
  {
    if (this.iDebug & 1 && this.bCanLog)
    {
      console.info(message);
    }
  },

  warn: function(message)
  {
    if (this.iDebug & 2 && this.bCanLog)
    {
      console.warn(message);
    }
  },

  error: function(message)
  {
    if (this.iDebug & 4 && this.bCanLog)
    {
      console.error(message);
    }
  },

  setFold: function(sFold) {
	
	  if (sFold == 'above') {
		  this.sFold = 'atf';
	  } else if (sFold == 'below') {
		  this.sFold = 'btf';
	  } else if (sFold == 'span') {
		  this.sFold = 'stf';
	  }
	  
  },
  
  show: function(sSection, nWidth, sCDNURL)
  {
    this.sSection = sSection ? sSection : TNCMS_Section;
    this.nWidth = nWidth ? nWidth : this.nWidth;
    this.sCDNURL = sCDNURL ? sCDNURL.replace(/^https?:\/\//i, '') : false;
    this.info('Generating ad for ' + this.sDomain + ' in ' + this.sPosition);

    for (nAdId in this.oAds)
    {    	
      //remove ads that have already been displayed
      if (TNCMS_Used_Ad[nAdId])
      {
        this.info('Removing ad #' + this.oAds[nAdId] + ' because it is already being displayed.');
        delete this.oAds[nAdId];
        continue;
      }

      if (!this.oAds[nAdId].uri && ((this.oAds[nAdId].type == 'html' && !this.oAds[nAdId].html) || this.oAds[nAdId].type == 'image' || this.oAds[nAdId].type == 'video'))
      {
        this.info('Removing ad #' + nAdId + ' because it is of type \'' + this.oAds[nAdId].type + '\' and is missing its mandatory asset uri.');
        delete this.oAds[nAdId];
        continue;
      }

      //if there isn't a function to handle this ad type then throw it away!
      if (typeof(window['tncms_ad_' + this.oAds[nAdId].type]) != 'function')
      {
        this.info('Removing ad #' + nAdId + ' because it is of type (' + this.oAds[nAdId].type + ') and there is no callback registerd for that.');
        delete this.oAds[nAdId];
        continue;
      }
      
      //don't allow more than 16 DotConnect ads on any given page
      if (this.oAds[nAdId].type == 'dotconnect' && TNCMS_DotConnect_Tile > 16)
      {
        this.info('Removing ad #' + nAdId + ' because it is a DotConnect ad and there are already 16 DoctConnect ads on this page.');
        delete this.oAds[nAdId];
        continue;
      }

      //if this ad has a keyword list and it's not empty then only allow this ad if it matches a meta keyword
      if (this.oAds[nAdId].keywords && this.oAds[nAdId].keywords.length > 0)
      {
        bKeep = 0;

        //see if any of the ad's keywords match the meta keywords
        for (var i = 0; i < this.oAds[nAdId].keywords.length; i++)
        {
          //add spaces on either side to make the search matches the whole word only
          //lowercase the word so the search is case insensitive
          var oRegex = new RegExp(' ' + this.oAds[nAdId].keywords[i].toLowerCase() + ' ', '');

          if (TNCMS_Meta_Keywords.match(oRegex))
          {
            bKeep = 1;
            break;
          }
        }

        //if there's no matches then remove that ad
        if (!bKeep)
        {
          this.info('Removing ad #' + nAdId + ' because it does not match any keywords on this page.');
          delete this.oAds[nAdId];
          continue;
        }
      }

      var oToday = new Date();
      var oStartDate = new Date();
      var oEndDate = new Date();
      var aDateParts = this.oAds[nAdId].startdate.split('-');
      oStartDate.setFullYear(aDateParts[0], (aDateParts[1]-1), aDateParts[2]);
      aDateParts = this.oAds[nAdId].enddate.split('-');
      oEndDate.setFullYear(aDateParts[0], (aDateParts[1]-1), aDateParts[2]);

      if ((oStartDate > oToday) || (oEndDate < oToday))
      {
        this.info('Removing ad #' + nAdId + ' because it has either not started yet or has already ended.');
        delete this.oAds[nAdId];
        continue;
      }

      //Throw out ads that do not fit the width limiter if present if width is defined in the ad. 
      if (this.nWidth && this.oAds[nAdId].width && this.oAds[nAdId].width > this.nWidth)
      {
        this.info('Removing ad #' + nAdId + ' because it is too wide for this region.');
        delete this.oAds[nAdId];
        continue;
      }

      //throw out ads that have hit their impression limits
      if (bAdManCurve)
      {
        if (typeof aTNCMS_Day_Impressions != "undefined" && aTNCMS_Day_Impressions[nAdId] && this.oAds[nAdId].dailyLimit && this.oAds[nAdId].dailyLimit <= aTNCMS_Day_Impressions[nAdId])
        {
          this.info('Removing ad #' + nAdId + ' because it has already met its daily impression limit.');
          delete this.oAds[nAdId];
          continue;
        }

        if (typeof aTNCMS_Total_Impressions != "undefined" && aTNCMS_Total_Impressions[nAdId] && this.oAds[nAdId].totalLimit && this.oAds[nAdId].totalLimit <= aTNCMS_Total_Impressions[nAdId])
        {
          this.info('Removing ad #' + nAdId + ' because it has already met its total impression limit.');
          delete this.oAds[nAdId];
          continue;
        }
      }
      else
      {
        if (typeof aTNCMS_Day_Limit != "undefined" && (aTNCMS_Day_Limit[nAdId] || aTNCMS_Day_Limit[nAdId] == 0) && this.nTodayTimestamp > aTNCMS_Day_Limit[nAdId])
        {
          this.info('Removing ad #' + nAdId + ' because it has already met its daily impression limit.');
          delete this.oAds[nAdId];
          continue;
        }

        if (typeof aTNCMS_Total_Limit != "undefined" && (aTNCMS_Total_Limit[nAdId] || aTNCMS_Total_Limit[nAdId] == 0) && this.nTodayTimestamp > aTNCMS_Total_Limit[nAdId])
        {
          this.info('Removing ad #' + nAdId + ' because it has already met its total impression limit.');
          delete this.oAds[nAdId];
          continue;
        }
      }

      //do dayparting checks
      if (this.oAds[nAdId].daypart_enabled && this.oAds[nAdId].daypart_enabled != '')
      {
        //if dayparting is enabled on this ad but it has no schedule at all then the ad shouldn't run
        if (!this.oAds[nAdId].daypart_schedule)
        {
          this.info('Removing ad #' + nAdId + ' because day parting is enabled but there is not schedule.');
          delete this.oAds[nAdId];
          continue;
        }

        var adSchedule = eval("(" + this.oAds[nAdId].daypart_schedule + ")" );

        //if there is a schedule but the required day isn't set then the ad shouldn't run
        if (!adSchedule[TNCMS_Ad_DayPart.day])
        {
          this.info('Removing ad #' + nAdId + ' because day parting is enabled and this day is not in the schedule.');
          delete this.oAds[nAdId];
          continue;
        }

        //if there *is* a schedule and it's *not* set to "allday"
        if (!adSchedule[TNCMS_Ad_DayPart.day].allday)
        {
          //and either bound is false then the ad shouldn't run 
          if (!adSchedule[TNCMS_Ad_DayPart.day][TNCMS_Ad_DayPart.startBound] || !adSchedule[TNCMS_Ad_DayPart.day][TNCMS_Ad_DayPart.endBound])
          {
            this.info('Removing ad #' + nAdId + ' because day parting is enabled and it is not in the the start or end bound.');
            delete this.oAds[nAdId];
            continue;
          }
        }
      }

      //Frequency User Check if the user has seen this ad enough times dispatch it with severe prejudice!
      if (this.oAds[nAdId].frequency_limit)
      {
        //then we need to check the cookie to see what the current count is if it exists
        sValueName = 'tncms_ad_' + nAdId;
        sValue = this._readCookie(sValueName);

        if (sValue)
        {
          aData = sValue.split('&');
          nAdCount = parseInt(aData[0], 10);

          if (nAdCount >= this.oAds[nAdId].frequency_limit)
          {
            this.info('Removing ad #' + nAdId + ' because it has met its frequency limit.');
            delete this.oAds[nAdId];
            continue;
          }
        }
      }

      //if the lat, long and proximity are in the ad and the location has been detected
      //then only allow the ad if the ip-location is inside the proximity limit of the ad
      if (this.oAds[nAdId]['lat'] && this.oAds[nAdId]['long'] && this.oAds[nAdId].proximity && TNCMS_Location)
      {
        //ad lat / long are already in radians and so don't need converting.
        var sinDeltaLat = Math.sin(((TNCMS_Location['lat'] * Math.PI / 180) - this.oAds[nAdId]['lat']) / 2);
        var sinDeltaLong = Math.sin(((TNCMS_Location['long'] * Math.PI / 180) - this.oAds[nAdId]['long']) / 2);
        var a = Math.pow(sinDeltaLat, 2) + Math.pow(sinDeltaLong, 2) * Math.cos(this.oAds[nAdId]['lat']) * Math.cos(TNCMS_Location['lat'] * Math.PI / 180);

        if ((7974 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))) > this.oAds[nAdId].proximity)
        {
          this.info('Removing ad #' + nAdId + ' because the reader was not detected within the configured porximity.');
          delete this.oAds[nAdId];
          continue;
        }
      }
    }

    if (this.oAds.length == 0)
    {
      this.warn('All ads have been eliminated from the ad list for this position (' + this.sPosition + ').');
      document.write('<!-- All ads have been eliminated from the ad list for this position (' + this.sPosition + '). -->');
      return true;
    }

    oValidAds = this._getAdsBySection(this.sSection, this.oAds);

    //now that we have a generated a list of valid ads we don't need this.oAds any more...
    //remove it so that it won't accidenatlly be re-used.
    this.oAds = [];

    if (oValidAds.length == 0)
    {
      this.warn('There are no ads available in this section (' + this.sSection + ') for this position (' + this.sPosition + ').');
      document.write('<!-- There are no ads available in this section (' + this.sSection + ') for this position (' + this.sPosition + '). -->');
      return true;
    }

    //get an ad based on the weights assigned to the ad
    oAd = this._getAdUsingWeight(oValidAds);

    if (!oAd)
    {
      document.write('<!-- There are no ads available to this position at this time -->');
      return true;
    }

    oAd.domain = this.sDomain;
    oAd.securedomain = this.sSecureDomain;
    oAd.cdn = this.sCDNURL;
    oAd.relative = this.bRelative;
    oAd.admin = this.iDebug;
    oAd.section = this.sSection;
    oAd.position = this.sPosition;
    oAd.fold = this.sFold;
    bNewTracking = false;

    if (!oAd.uuid)
    {
      oAd.uri.match(/\/content\/tncms\/assets\/v3\/bannerad\/.\/..\/(.+?)\//);
      oAd.uuid = RegExp.$1;
    }

    if (this.sCDNURL && TNCMS.Tracking)
    {
      //ignore the original asseturl as it may contain the old style tracking code...
      oAd.asseturl = 'https://' + this.sCDNURL + oAd.uri;
      bNewTracking = true;
    }
    else if (oAd.asseturl == undefined)
    {
      sDomain = location.protocol == 'https:' && this.sSecureDomain ? this.sSecureDomain : this.sDomain;
      oAd.asseturl = location.protocol + '//' + sDomain + '/tncms/ads/[random_number]/' + this.sPosition + '/' + oAd.uuid.substr(0,1) + '/' + oAd.uuid.substr(1,2) + '/' + oAd.uuid + '/current.json';
      oAd.file_modified = '';
    }

    if (bNewTracking)
    {
      if (oAd.clickuri)
      {
          clickuri = oAd.clickuri.match(/^[htpsmailo]+:/i) ? oAd.clickuri : 'http://' + oAd.clickuri;
          oAd.clickuri = TNCMS.Tracking.getRedirectURL
          ({
            domain: this.isSecure ? oAd.securedomain : oAd.domain,
            app: 'bannerad',
            metric: 'clicks',
            id: oAd.section + '/' + oAd.position + '/' + oAd.uuid,
            url: unescape(clickuri)
          });
      }
      else if (oAd.type == 'html')
      {
        oAd.clickuri = TNCMS.Tracking.getRedirectURL
        ({
          domain: this.isSecure ? oAd.securedomain : oAd.domain,
          app: 'bannerad',
          metric: 'clicks',
          id: oAd.section + '/' + oAd.position + '/' + oAd.uuid,
          url: 'TNCMS_AD_TRACKER'
        });
        oAd.clickuri = oAd.clickuri.replace(/TNCMS_AD_TRACKER/, '');
      }
    }
    else
    {
      tmpPath = Math.floor(Math.random()*100000000) + "/" +  this.sSection;    

      if (oAd.clickuri)
      {
          clickuri = oAd.clickuri.match(/^[htpsmailo]+:/i) ? oAd.clickuri : 'http://' + oAd.clickuri;
          oAd.clickuri = oAd.asseturl.replace("[random_number]", 'c' + tmpPath) + "?r=" + clickuri;
      }

      oAd.asseturl = oAd.asseturl.replace("[random_number]", tmpPath) +  "?_dc=" + oAd.file_modified;

      //if the relative option is set make this asseturl a relative link. 
      if (this.bRelative)
      {
        oAd.asseturl = oAd.asseturl.replace(/http(s)?:\/\/.*?\//, "/");
      }
    }

    if (this.iDebug & 1)
    {
      sAdRegion = '<div style="border: 1px solid black" width="100%"> Ad Position: ' + oAd.section + ' : ' + oAd.position + ' Ad ID: ' + oAd.adid + ' (' + oAd.type + ':API 1)</div>';
      document.write(sAdRegion);
    }

    if (oAd.type == 'video')
    {
      oAd.rolltype = this.oPosSettings[this.sSection].video_rolltype;
      oAd.autoplay = parseInt(this.oPosSettings[this.sSection].video_autoplay, 10);
      oAd.embed = parseInt(this.oPosSettings[this.sSection].video_embed, 10);
      oAd.volume = parseInt(this.oPosSettings[this.sSection].video_volume, 10);
    }

    if ( ! bNewTracking ) {
      switch (oAd.type)
      {
        case 'html':
          var strippedUrl = oAd.asseturl.replace(/\?_dc=[0-9]+/, '').replace(/\?dc=[0-9]+/, '').replace(/-revisions/, '');
          strippedUrl.match(/\/tncms\/ads\/\d+?\/.+?\/.\/..\/(...\/)?(.+?)\//);
          var sVarName = RegExp.$2;
          sVarName = sVarName.replace(/-/g, '');

          var sHTML = "<scr" + "ipt language='javascript'>\n";
          sHTML += "var pos_" + sVarName + " = '" + this.sPosition + "';\n";
          sHTML += "var sec_" + sVarName + " = '" + this.sSection + "';\n";
          sHTML += "</scr" + "ipt>\n";
          document.write(sHTML);
          break;

        //all the non-file types will use this method to track their impressions
        case 'dotconnect':
        case 'expandable':
        case 'pagecurl':        
        case 'text':
          document.write('<scr' + 'ipt type="text/javascript" src="' + oAd.asseturl + '"></scr' + 'ipt>');
          break;
      }
    }

    if (this.iDebug & 1 && this.bCanLog)
    {
      //clone the data so that when it is changed by a callback it isn't retroactivly changed in our console output! 
      var oTemp = {};

      for (x in oAd)
      {
        oTemp[x] = oAd[x];
      }

      this.info(oTemp);
    }

    window['tncms_ad_' + oAd.type](oAd);
    
    TNCMS.Tracking.addEvent({
    	'domain': this.isSecure ? oAd.securedomain : oAd.domain, 
    	'app': 'bannerad', 
    	'metric': 'impressions', 
    	'id': oAd.section + '/' + oAd.position + '/' + oAd.uuid
    });

    //Mark the ad as used. 
    if (oAd.type != 'dotconnect')
    {
      TNCMS_Used_Ad[oAd.adid] = 1;
    }

    this._updateFreqCount(oAd);
    return true;
  },

  setDebug: function(iDebugLevel)
  {
    this.iDebug = iDebugLevel ? iDebugLevel : 1;
  },

  setRelative: function()
  {
    this.bRelative = true;
  },

  setWidth: function(nWidth)
  {
    this.nWidth = nWidth;
  },

  _getAdsBySection: function(sSection, oAds)
  {
    oValidAds = [];

    for (nAdId in oAds)
    {
      var adSections = oAds[nAdId].sections;

      for (var i=0; i < adSections.length; i++)
      {
        if (adSections[i] == sSection)
        {
          oValidAds.push(oAds[nAdId]);
        }
      }
    }

    //Need to recursively attempt to find ads and adjust the section we are working on to where we find ads. 
    //if we do not find any ads check the the ros section 
    if (oValidAds.length > 0)
    {
      this.info('Returning ads for section: ' + sSection);
      this.sSection = sSection;
      return oValidAds;
    }

    sMatch = sSection.match(/(\/[\w\-]+)$/g);
    if (sMatch)
    {
      return this._getAdsBySection(sSection.replace(sMatch, ''), oAds);
    }

    if (sSection == 'ros')
    {
      this.info('Returning ads for section: ' + sSection);
      return oValidAds;
    }
    
    return this._getAdsBySection('ros', oAds);
  },

  _getAdUsingWeight: function(oAds)
  {
    var nIterator = 0;
    var nLockedTotalPerc = 0;
    var nLimitedPerc = 0;
    var nUnlockedTotalPerc = 0;
    var nUnlockedAds = 0;
    var nNoAd = 0;

    for (var i = 0; i < oAds.length; i++)
    {
      oAds[i].weight = this._getAdWeightData(oAds[i]);

      if (oAds[i].weight.locked == 1)
      {
        nLockedTotalPerc += parseFloat(oAds[i].weight.percent);
        oAds[i].minRange = nIterator;
        oAds[i].maxRange = nIterator + (10000 * parseFloat(oAds[i].weight.percent));
        this.info('Weight range for ad #' + oAds[i].adid + ': ' + oAds[i].minRange + ' to ' + oAds[i].maxRange);
        nIterator = oAds[i].maxRange;
      }
      else if (oAds[i].weight.locked == 2)
      {
        nLimitedPerc += parseFloat(oAds[i].weight.percent);
      }
      else
      {
        nUnlockedTotalPerc += parseFloat(oAds[i].weight.percent);
        nUnlockedAds++;
      }
    }

    if ((nLimitedPerc + nUnlockedTotalPerc) > (100 - nLockedTotalPerc))
    {
      nMultiplier = (100 - nLockedTotalPerc) / 100;

      for (i = 0; i < oAds.length; i++)
      {
        if (oAds[i].weight.locked != 1)
        {
          oAds[i].minRange = nIterator;
          oAds[i].maxRange = nIterator + (10000 * parseFloat(oAds[i].weight.percent) * nMultiplier);
          this.info('Weight range for ad #' + oAds[i].adid + ': ' + oAds[i].minRange + ' to ' + oAds[i].maxRange);
          nIterator = oAds[i].maxRange;
        }
      }
    }
    else if ((nLimitedPerc + nUnlockedTotalPerc) <= (100 - nLockedTotalPerc))
    {
      if (nUnlockedTotalPerc == 0)
      {
        nNoAd = 100 - nLockedTotalPerc - nLimitedPerc;

        for (i = 0; i < oAds.length; i++)
        {
          if (oAds[i].weight.locked != 1)
          {
            oAds[i].minRange = nIterator;
            oAds[i].maxRange = nIterator + (10000 * parseFloat(oAds[i].weight.percent));
            this.info('Weight range for ad #' + oAds[i].adid + ': ' + oAds[i].minRange + ' to ' + oAds[i].maxRange);
            nIterator = oAds[i].maxRange;
          }
        }
      }
      else
      {
        nNewPercent = (100 - nLockedTotalPerc - nLimitedPerc) / nUnlockedAds;

        for (i = 0; i < oAds.length; i++)
        {
          if (oAds[i].weight.locked != 1)
          {
            nPercent = oAds[i].weight.locked < 1 ? nNewPercent : oAds[i].weight.percent;
            oAds[i].minRange = nIterator;
            oAds[i].maxRange = nIterator + (10000 * parseFloat(nPercent));
            this.info('Weight range for ad #' + oAds[i].adid + ': ' + oAds[i].minRange + ' to ' + oAds[i].maxRange);
            nIterator = oAds[i].maxRange;
          }
        }
      }
    }

    nIterator += nNoAd;
    var randomWeight = Math.floor(Math.random() * nIterator);  

    for (i = 0; i < oAds.length; i++)
    {
      if (oAds[i].minRange <= randomWeight && oAds[i].maxRange > randomWeight)
      {
        this.info('Ad #' + oAds[i].adid + '(type: ' + oAds[i].type + ') chosen from ' + this.sDomain + ' to display in ' + this.sPosition);
        return oAds[i];
      }
    }

    //if you get here then there was no ad selected randomly....
    this.warn('No ad was selected from the list');
    return false;
  },

  _getAdWeightData: function(oAd)
  {
    for (var j=0; j < oAd.weights.length; j++)
    {
      if (oAd.weights[j].section == this.sSection)
      {
        //this is the new section and if new data exists
        //and the nTrafficRatio and nTrafficEstimate are greater than zero
        //then we use this instead of the pre-set value
        if (oAd.dailyLimit && typeof aTNCMS_Day_Impressions != 'undefined' && aTNCMS_Day_Impressions[oAd.adid] && this.nTrafficRatio && this.nTrafficEstimate)
        {
          nWantedImpressions = (oAd.dailyLimit - aTNCMS_Day_Impressions[oAd.adid]) * this.nTrafficRatio;

          //if the nWantedImpressions is greater than or equal to the current this.nTrafficEstimate
          //then it should have to have a normal (unadjusted) chance along with all the non-limited ads
          //otherwise the limited ads will get a disproprtionately large percentage of the this section's impressions
          if (nWantedImpressions >= this.nTrafficEstimate)
          {
            this.info('Wanted impressions for ' + oAd.adid + ' is greater than the current traffic estimate: using default weights for ad#' + oAd.adid);
            return oAd.weights[j];
          }

          nWeight = nWantedImpressions / this.nTrafficEstimate;
          this.info('Overriding default weight: using traffic curve for ad#' + oAd.adid);
          return {'section': this.sSection, 'percent': 100 * nWeight, 'locked': 2};
        }

        this.info('Using default weight in favor of traffic curve for ad#' + oAd.adid);
        return oAd.weights[j];
      }
    }

    this.warn('No weight data found for ad#' + oAd.adid + ' found: using 0');
    return 0;
  },

  _updateFreqCount: function(oAd)
  {
    if (!oAd.frequency_limit)
    {
      return false;
    }

    sValueName = 'tncms_ad_' + oAd.adid;

    if (!this._readCookie(sValueName))
    {
      //create a new cookie
      if (oAd.frequency_number && oAd.frequency_type)
      {
        var oDate = new Date();

        switch (oAd.frequency_type)
        {
          case 'minutes':
            oDate.setTime(oDate.getTime() + (oAd.frequency_number*60*1000));
            break;

          case 'hours': 
            oDate.setTime(oDate.getTime() + (oAd.frequency_number*60*60*1000));
            break;

          case 'days':
            oDate.setTime(oDate.getTime() + (oAd.frequency_number*24*60*60*1000));
            break;          
        }

        var cookieExp = "; expires=" + oDate.toGMTString();
        this.info('Creating frequency limit cookie for ' + oAd.adid);
        document.cookie = sValueName + '=1&' + escape(oDate.toGMTString()) + cookieExp + '; path=/';
      }
    }
    else
    {
      //read the existing cookie and increment the value
      sCookieValue = this._readCookie(sValueName);
      aData = sCookieValue.split('&');
      nAdCount = parseInt(aData[0], 10) + 1;
      this.info('Incrementing frequency limit cookie for ' + oAd.adid);
      document.cookie = sValueName + "=" + nAdCount + '&' + aData[1] + '; expires=' + unescape(aData[1])+ '; path=/';
    }  
  },

  _readCookie: function(name)
  {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');

    for (var i=0;i < ca.length;i++)
    {
      var c = ca[i];

      while (c.charAt(0) == ' ')
      {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) == 0)
      {
        return c.substring(nameEQ.length,c.length);
      }
    }

    return null;
  }
};